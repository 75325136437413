import httpClient from "./httpClient";
const END_POINT = "/api/reports_types";

const getAllTemplates = (item) => httpClient.get(END_POINT+'_list/' + item);
const getAllTemplatesPaginated = (page) => httpClient.get(END_POINT +'?page=' + page);
const getTemplate = (id) => httpClient.get(END_POINT + '/' + id);
const addTemplate = (rapportType) => httpClient.post(END_POINT, rapportType);
const updateTemplate = (rapportType) => httpClient.put(END_POINT, rapportType);
const deleteTemplate = (id) => httpClient.delete(END_POINT + '/' + id)
export {
  getTemplate,
  getAllTemplates,
  getAllTemplatesPaginated,
  addTemplate,
  updateTemplate,
  deleteTemplate
}