import httpClient from "./httpClient";
const END_POINT = "/api/report";
const addReport = report => httpClient.post(END_POINT, report);
const updateReport = (id, report) =>
    httpClient.post(END_POINT + "-edit/" + id, report);
const getAllUserReports = (page, filterList) => httpClient.post('/api/getAllUserReports?page=' + page, filterList);
const getAllUserReportsByAdmin = (page, filterList) => httpClient.post('/api/report/all?page='+page, filterList);
const getReport = id => httpClient.get(END_POINT + "lines/" + id);
const getReportList = endDate =>
    httpClient.get(END_POINT + "/filter/" + endDate);
const getAllReportByAdmin = (page, filterList) =>
    httpClient.post(END_POINT + "/all?page=" + page, filterList);
const downloadReportFile = report =>
    httpClient.post(END_POINT + "/download", report,  {responseType: 'blob'});
const exportReport = id =>
    httpClient.get(END_POINT + "/export/" + id,  {responseType: 'blob'});
export {
  addReport,
  updateReport,
  getAllUserReports,
  getAllUserReportsByAdmin,
  getReportList,
  getReport,
  getAllReportByAdmin,
  downloadReportFile,
  exportReport
};
