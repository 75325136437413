<template>
  <v-container class="pa-16">
    <v-data-table
      :headers="headers"
      :items="reports_lines"
      class="elevation-0 myTable"
      disable-pagination
      hide-default-footer
      @click:row="handleTableClick"
      :options.sync="options"
      :server-items-length="total"
      :header-props="{ sortIcon: null }"
    >
      <template v-slot:no-data> Aucune ligne </template>
      <template v-slot:top>
        <v-toolbar flat extended extension-height="100">
          <v-toolbar-title id="toolbar-title">Rapports</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-dialog v-model="filterDialog" max-width="836px">
            <v-card>
              <v-card-title class="justify-space-between">
                <span class="headline">Filtre</span>
                <v-btn color="black" dark icon right @click="close">
                  <v-icon color="#B2BBAB">mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="6" sm="6" class="ma-0 pt-0 pb-0">
                      <AutoComplete
                        id="agent"
                        v-model="editedItem.agent"
                        :items="agents"
                        label="Agent"
                        item-text="name"
                        return-object
                        multiple
                        deletable-chips
                        small-chips
                      />
                    </v-col>
                    <v-col cols="12" md="6" sm="6" class="ma-0 pt-0 pb-0">
                      <AutoComplete
                        id="dossier"
                        v-model="editedItem.dossier"
                        :items="dossiers"
                        label="Dossier"
                        item-text="name"
                        multiple
                        deletable-chips
                        small-chips
                        return-object
                      />
                    </v-col>
                    <v-col cols="12" md="6" sm="6" class="ma-0 pt-0 pb-0">
                      <AutoComplete
                        id="type"
                        v-model="editedItem.type"
                        :items="reports_types"
                        label="Type de rapport"
                        item-text="name"
                        return-object
                        multiple
                        deletable-chips
                        small-chips
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                      sm="6"
                      class="ma-0 pb-0 mt-n3"
                      v-if="!disabledExactDate"
                    >
                      <label>Date</label>
                      <v-checkbox
                        dense
                        hide-details
                        class="ma-0 pa-0 d-inline-block select-multiple-date"
                        v-model="checkDate"
                        @change="handleMultipleDate(true)"
                      ></v-checkbox>
                      <custom-date-picker v-model="editedItem.exact_date" />
                    </v-col>
                    <v-col
                      cols="12"
                      md="3"
                      sm="6"
                      class="ma-0 pb-0 mt-n3"
                      v-if="showMultipleDates"
                    >
                      <label>Date début</label>
                      <v-checkbox
                        dense
                        hide-details
                        class="ma-0 pa-0 d-inline-block select-multiple-date"
                        v-model="checkDate"
                        @change="handleMultipleDate(false)"
                      ></v-checkbox>
                      <custom-date-picker v-model="editedItem.start_date" />
                    </v-col>
                    <v-col
                      cols="12"
                      md="3"
                      sm="6"
                      class="ma-0 pb-0 mt-n3"
                      v-if="showMultipleDates"
                    >
                      <label>Date fin</label>
                      <custom-date-picker v-model="editedItem.end_date" />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions class="pb-6">
                <Button
                  action_name="Filtrer"
                  color="#86CC26"
                  @clicked="save"
                  style="width: 220px"
                  class="ml-5 mr-5"
                  :loading="loadingFilter"
                />
                <span style="color: #b2bbab" @click="handleReset">Réinitialiser</span>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <template v-slot:extension>
            <div class="filter-block">
              <div>
                <template v-for="(agent, index) in editedItem.agent">
                  <v-chip
                    class="ma-1"
                    :key="agent.name"
                    close
                    @click:close="handleChipDelete(index, 'agent')"
                    >Agent:{{ agent.name }}</v-chip
                  >
                </template>
                <template v-for="(type, index) in editedItem.type">
                  <v-chip
                    class="ma-1"
                    :key="type.name"
                    close
                    @click:close="handleChipDelete(index, 'type')"
                    >Type de rapport:{{ type.name }}</v-chip
                  >
                </template>
                <template v-for="(dossier, index) in editedItem.dossier">
                  <v-chip
                    class="ma-1"
                    :key="dossier.name"
                    close
                    @click:close="handleChipDelete(index, 'dossier')"
                    >Dossier:{{ dossier.name }}</v-chip
                  >
                </template>
                <template v-if="editedItem.exact_date">
                  <v-chip class="ma-1" close @click:close="handleDateDelete('exact_date')"
                    >Date:{{ editedItem.exact_date }}</v-chip
                  >
                </template>
                <template v-if="editedItem.start_date && editedItem.end_date">
                  <v-chip class="ma-1" close @click:close="handleMultipleDateDelete"
                    >Entre le :{{ editedItem.start_date }} et le :
                    {{ editedItem.end_date }}</v-chip
                  >
                </template>
                <template v-if="editedItem.start_date && !editedItem.end_date">
                  <v-chip class="ma-1" close @click:close="handleDateDelete('start_date')"
                    >Aprés le :{{ editedItem.start_date }}</v-chip
                  >
                </template>
                <template v-if="editedItem.end_date && !editedItem.start_date">
                  <v-chip class="ma-1" close @click:close="handleDateDelete('end_date')"
                    >Avant le :{{ editedItem.end_date }}</v-chip
                  >
                </template>
              </div>
              <div>
                <v-chip class="chip-delete">
                  <v-icon @click="filterDialog = true" outlined> mdi-filter </v-icon>
                </v-chip>
              </div>
            </div>
          </template>
        </v-toolbar>
      </template>
      <template v-slot:item.exact_date="{ item }">
        {{ item.exact_date | moment("DD/MM/YYYY") }}
      </template>
      <template v-slot:item.agent="{ item }">
        <Avatar :item="item.agent" />
        <span class="ml-2">{{ item.agent.name }}</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <ReportDetails :report="item" v-model="detailsDialog" />
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import { Avatar, Button, AutoComplete, CustomDatePicker } from "@/components/base"
import { infinityScrollMixin } from "@/utils/infinityScrollMixin"
import { getAllTemplates } from "@/services/reportsTypes"
import { getDossiersList } from "@/services/dossiers.api"
import { getAllUserReportsByAdmin } from "@/services/reports.api"
import { getAllActiveUsers } from "@/services/users.api"
import ReportDetails from "@/components/common/report-details"

export default {
  components: { Button, Avatar, AutoComplete, CustomDatePicker, ReportDetails },
  mixins: [infinityScrollMixin],
  data: () => ({
    loadingFilter: false,
    showItem: {},
    detailsDialog: false,
    isValid: false,
    loadingAdd: false,
    loadingEdit: false,
    loadingContinue: false,
    showEditButton: false,
    reports_types: [],
    fileTypeError: false,
    total: 0,
    options: {},
    disabledExactDate: false,
    showMultipleDates: false,
    checkDate: false,
    modal: false,
    dossiers: [],
    agents: [],
    filterDialog: false,
    reportLine: [
      {
        report: {
          agent: {
            name: null
          }
        }
      }
    ],
    items: [],
    dialog: false,
    headers: [
      {
        text: "Agent",
        align: "start",
        value: "agent"
      },
      { text: "Role", value: "role.name" },
      { text: "Dossier", value: "dossier.name" },
      { text: "Type de rapport", value: "reports_types.name" },
      {
        text: "Date",
        value: "exact_date"
      },
      { text: "Actions", value: "actions", sortable: false }
    ],
    reports_lines: [],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    pageNumber: 1,
    last_page: 0
  }),
  watch: {
    options: {
      handler() {
        this.editedItem.order_by = this.options.sortBy[0]
        this.editedItem.order_by_direction = this.options.sortDesc[0]
        this.reports_lines = []
        this.pageNumber = 1
        this.getAllReports()
      },
      deep: true
    },
    endOfTheScreen(endOfTheScreen) {
      if (endOfTheScreen === true && this.pageNumber < this.last_page) {
        this.pageNumber += 1
        getAllUserReportsByAdmin(this.pageNumber, this.editedItem).then((response) => {
          this.reports_lines = [...this.reports_lines, ...response.data.data]
        })
      }
    },
    dialog(val) {
      val || this.close()
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    handleMultipleDateDelete() {
      this.editedItem["start_date"] = ""
      this.editedItem["end_date"] = ""
      this.save()
    },
    handleMultipleDate(status) {
      if (status === true) {
        this.editedItem["exact_date"] = ""
      } else {
        this.editedItem["start_date"] = ""
        this.editedItem["end_date"] = ""
      }
      this.showMultipleDates = !this.showMultipleDates
      this.disabledExactDate = !this.disabledExactDate
    },
    handleReset() {
      this.editedItem = {}
      this.save()
    },
    handleChipDelete(index, key) {
      this.editedItem[key].splice(index, 1)
      this.save()
    },
    handleDateDelete(key) {
      this.editedItem[key] = ""
      this.save()
    },
    handleTableClick(row) {
      this.lineDetail = true
      this.reportLine = row
    },
    getAllReports() {
      getAllUserReportsByAdmin(this.pageNumber).then((response) => {
        this.reports_lines = response.data.data
        this.last_page = response.data.last_page
        this.total = response.data.total
      })
    },
    initialize() {
      getAllTemplates().then((response) => {
        this.reports_types = response.data
      })
      this.getAllReports()
      getAllActiveUsers().then((response) => {
        this.agents = response.data
      })
      getDossiersList().then((response) => {
        this.dossiers = response.data
      })
    },
    close() {
      this.filterDialog = false
      this.dialog = false
      // this.$nextTick(() => {
      //   this.editedItem = Object.assign({}, this.defaultItem);
      //   this.editedIndex = -1;
      // });
    },
    save() {
      this.loadingFilter = true
      this.pageNumber = 1
      getAllUserReportsByAdmin(this.pageNumber, this.editedItem).then((response) => {
        this.loadingFilter = false
        this.reports_lines = response.data.data
        this.last_page = response.data.last_page
        this.filterDialog = false
      })
    }
  }
}
</script>
<style scoped>
.select-multiple-date {
  margin-top: -5px !important;
}
</style>
