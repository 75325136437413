<template>
  <v-dialog v-model="detailsDialog" max-width="836px">
    <template v-slot:activator="{ on, attrs }">
      <Button
        v-bind="attrs"
        v-on="on"
        class="mb-2"
        action_name="Voir"
        color="primary"
        text
        @clicked="detailsDialog = true"
      />
    </template>
    <v-card>
      <v-card-title class="justify-space-between">
        <span class="headline" style="color:#11887E">{{ report.reports_types.name }}</span>
        <v-btn ref="close" color="black" icon right @click="detailsDialog=false">
          <v-icon color="#B2BBAB">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col col="12">
              <div class="d-flex">
                <Avatar :item="report.agent" />
                <div class="d-flex flex-column">
                  <span class="ml-2 agent-name" v-if="report.agent">{{report.agent.name }}</span>
                  <span class="ml-2">{{ report.role.name }}</span>
                </div>
  <div class="ml-5 d-flex align-start">
    <img :src="require('@/assets/calendar.svg')" class="calendar-image" alt="calendar"/>
    <span class="ml-2 report-date">{{ report.exact_date | moment("ddd DD-MM-YYYY") }}</span>
  </div>

              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span class="dossier-name">{{ report.dossier.name }}</span>
            </v-col>
          </v-row>
          <v-row>
            <p  v-html="report.content"></p>
          </v-row>
          <v-row :id="report.id" class="d-none">
            <v-card-title class="justify-space-between">
              <span class="headline" style="color:#11887E">{{ report.reports_types.name }}</span>
            </v-card-title>
            <v-row>
              <v-col col="12">
                <div class="d-flex">
                  <Avatar :item="report.agent" />
                  <div class="d-flex flex-column">
                    <span style='  color: #003d42;
  font-family: "Lato", sans-serif;
  font-size: 12px;
  font-weight: bold;margin-left: 50px' v-if="report.agent">{{report.agent.name }}</span>
                    <span style="margin-left: 50px">{{ report.role.name }}</span>
                  </div>
                  <div class="ml-5 d-flex align-start">
                    <img :src="require('@/assets/calendar.svg')" class="calendar-image" alt="calendar"/>
                    <span class="ml-2 report-date">{{ report.exact_date | moment("ddd DD-MM-YYYY") }}</span>
                  </div>

                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span style='  font-family: "Lato", sans-serif;
  font-size: 26px;
  color: #86BC26;margin-top: 100px'>{{ report.dossier.name }}</span>
              </v-col>
            </v-row>
            <p  v-html="report.content"></p>
          </v-row>
          <v-row>
            <div class="files-block" v-if="report.file">
              <template v-for="file in report.file">
                <div :key="file">
                  <v-icon style="transform:rotate(20deg)">mdi-paperclip</v-icon>
                  <span class="pl-2 pr-3 file-name" @click="handleFileClick(file)">{{ file }}</span>
                </div>
              </template>
            </div>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="pb-6 justify-end">
        <Button color="#86CC26" @clicked="print" action_name="Imprimer" v-if="$store.getters.isAdmin"/>
        <Button color="#86CC26" @clicked="downloadReport" action_name="Télécharger" v-if="$store.getters.isAdmin"/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { Avatar, Button } from "@/components/base";
import { downloadReportFile, exportReport } from "@/services/reports.api";
export default {
  name: "report-details",
  components: { Button, Avatar },
  data() {
    return {
      detailsDialog: false
    };
  },
  props: {
    report: {
      type: Object,
      required: true
    }
  },
  methods: {
    downloadReport(){
      exportReport(this.report.id).then((response)=>{
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", this.report.id);
        document.body.appendChild(fileLink);
        fileLink.click();
      })
    },
    handleFileClick(item) {
      const sentItem = {};
      sentItem.id = this.report.id;
      sentItem.file = item;
      downloadReportFile(sentItem).then(response=>{
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", item);
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    print() {
      this.$htmlToPaper(this.report.id);
    }
  }
};
</script>

<style scoped>
.dossier-name {
  font-family: "Lato", sans-serif;
  font-size: 26px;
  color: #86BC26;
}
.files-block {
  border-radius: 26px;
  border: 1px solid #E6E6E6;
  width: 100%;
  min-height: 52px;
  display: flex;
  justify-content: flex-start;
  height: auto;
  align-items: center;
  padding-left: 15px
}
.file-name {
  color: #003d42;
  text-decoration: underline;
  cursor: pointer;
 }
.agent-name {
  color: #003d42;
  font-family: "Lato", sans-serif;
  font-size: 12px;
  font-weight: bold;
}
.report-date{
  font-family: "Lato", sans-serif;
  font-weight: normal;
  font-size: 12px;
  text-align: left;
  color: #003d42;

}
.calendar-image{
  height: 38px;
  min-width: 38px;
  width: 38px;
}
</style>
