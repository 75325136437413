import httpClient from "./httpClient";

const END_POINT = "/api/dossiers";
const getAllDossiers = (page, filterList) =>
  httpClient.post(END_POINT + "/all?page=" + page, filterList);
const getDossiersList = () => httpClient.get(END_POINT + "/getDossiersList");
const getDossier = (name, status) =>
  httpClient.get(END_POINT + "/" + name + "/" + status);
const addDossier = (dossier) => httpClient.post(END_POINT, dossier);
const getUserDossiers = () => httpClient.get(END_POINT + "/users");
const getUserRoleInDossier = (dossier) =>
  httpClient.post(END_POINT + "_role", { dossier });
const updateDossier = (id) => httpClient.put(END_POINT, id);
const searchDossier = (name, status) =>
  httpClient.get("/api/search/dossiers/" + name + "/" + status);
const exportAllProject = (filterList) =>
  httpClient.post(
    END_POINT + "/exportAllProject",
    { filterList },
    { responseType: "blob" }
  );
const getActiveDossiers = () => httpClient.get("api/getActiveDossiers");
const getTypesDossier = () => httpClient.get("api/getTypesDossier");
const getListFolderByAgentId = (page, filterList) =>
  httpClient.post("api/projects" + "/all?page=" + page, filterList);
const getFoldersConfigurations = (page, filterList) =>
  httpClient.post("/api/getConfigHours?page=" + page, filterList);
const updateConfigHours = (configuration) =>
  httpClient.post("/api/updateConfigHours", configuration);
const deleteConfigHours = (ids) =>
  httpClient.post("/api/deleteConfigHours", ids);
export {
  getAllDossiers,
  getDossier,
  addDossier,
  getUserDossiers,
  getUserRoleInDossier,
  updateDossier,
  searchDossier,
  getDossiersList,
  exportAllProject,
  getActiveDossiers,
  getTypesDossier,
  getListFolderByAgentId,
  getFoldersConfigurations,
  updateConfigHours,
  deleteConfigHours,
};
